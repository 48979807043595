import * as React from "react"
import {useState} from "react";
import DocumentationContent from "../../components/DocumentationContent"
import DocumentationSidebar from "../../components/DocumentationSidebar"
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import {FullSlideshowLightboxExample, SlideshowLightboxExampleDoc, SlideshowLightboxExample, 
  PropsDoc, ThemesDoc} from "../../components/Examples";
import Content, { HTMLContent } from '../../components/Content'
import Documentation from '../../components/Documentation'
import {SlideshowLightbox, Image} from "lightbox.js-react";
import { CopyBlock, CodeBlock, atomOneDark, solarizedDark, xt256 } from "react-code-blocks";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';



const VideoAndImageDemo = () => {
    const items = [
        {
          type: "htmlVideo", 
          videoSrc:"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          thumbnail:"https://peach.blender.org/wp-content/uploads/bbb-splash.png",
          alt: "Poster for the Big Buck Bunny film, featuring the bunny character in a green field, along with a purple butterfly"
        }, 
        { 
            type: "yt", 
            videoID :"IWVJq-4zW24",
            thumbnail: "https://img.youtube.com/vi/IWVJq-4zW24/hqdefault.jpg",
            alt: "Universe with stars"
          },
        { 
            src: "https://images.pexels.com/photos/247600/pexels-photo-247600.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", 
            alt: "Blue lake and trees in the distance, along with a blue sky and clouds"
          },
 
      ]

      let [isOpen, setIsOpen] = useState(false)

      return (
        <div>
          <button onClick={() => {setIsOpen(true)}}>Open Lightbox</button>
          <SlideshowLightbox images={items} lightboxIdentifier="lbox2" showThumbnails={true}
          className="container grid grid-cols-3 gap-2 mx-auto">
          {items.map((item) => (
            <img
              src={item.thumbnail || item.src}
              alt={item.alt}
              height={500}
              width={500}
              data-lightboxjs="lbox2"
            />
            ))}
          </SlideshowLightbox>
        </div>

      )
}

const VideoSupportDemo = () => {
    const items = [
        {
          type: "htmlVideo", 
          videoSrc:"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          thumbnail:"https://peach.blender.org/wp-content/uploads/bbb-splash.png",
          alt: "Poster for the Big Buck Bunny film, featuring the bunny character in a green field, along with a purple butterfly"
        }, 
        { 
            type: "yt", 
            videoID :"IWVJq-4zW24",
            thumbnail: "https://img.youtube.com/vi/IWVJq-4zW24/hqdefault.jpg",
            alt: "Universe with stars"
        }
      ]

      let [isOpen, setIsOpen] = useState(false)

      return (
        <div>
          {/* <button onClick={() => {setIsOpen(true)}}>Open Lightbox</button> */}
          <SlideshowLightbox images={items} lightboxIdentifier="lbox123" showThumbnails={true}
          className=" grid grid-cols-2 gap-2 mx-auto w-4/5 h-full w-full">
          {items.map((item) => (
            <div className="h-full">
                <img
                src={item.thumbnail || item.src}
                alt={item.alt}
                height={500}
                width={500}
                data-lightboxjs="lbox123"
                className="h-full object-cover"
                style={{height: "100%"}}
                />
            </div>

            ))}
          </SlideshowLightbox>
        </div>

      )
}

export default function NextJsGuideComponent () {
  const images = [{title: "Photon", src: "https://source.unsplash.com/600x400/?pastel+cloud"}, 
  {title: "Inversion", src: "https://source.unsplash.com/500x400/?pastel+clouds"},
  {title: "Legion", src: "https://source.unsplash.com/600x400/?pastel+sky"},
  {title: "Inversion", src: "https://source.unsplash.com/600x400/?pastel"},
  {title: "Twenty Neue", src: "https://source.unsplash.com/600x400/?sky+pastel"},
  {title: "Geometrik", src: "https://source.unsplash.com/600x400/?dreamy+clouds"},         
  {title: "Achtung", src: "https://source.unsplash.com/600x400/?pastel+fruit"},];
  const showLineNumbers = true;
  const wrapLines = true;
  const codeBlock = false;

  return <Layout>
  <Header />
  <section className="section section--gradient font-inter bg_white">
  
      <div className="pt-4">
      <main className="flex-grow">

          <section>
          <div className="max-w-6xl mx-auto px-2 sm:px-2">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Main content */}
              <div className="md:flex md:justify-between" data-sticky-container>

                  <DocumentationSidebar />

                  <div className="md:flex-grow documentation font-inter">

                    {/* <Header /> */}
                    <div className="text-lg text-gray-600">
                      <h1 className="h1 text-gray-900 mb-4 font-bold">HTML5 Picture Tag Support</h1>

                      <p className="mb-8">
                      Lightbox.js supports the HTML5 <code>picture</code> element, and in this guide, 
                      we'll be taking a look at how to display this element in the lightbox.

                      </p>

                    <h3 id="import" className="h3 text-gray-900 font-bold text-3xl mt-4" style={{ scrollMarginTop: '100px' }}>Import</h3>

                    <p>
                    Let's get started! First, we'll need to  
                    import the <code>SlideshowLightbox</code> component, plus the CSS file which provides 
                    the styling for the library:
                    </p>

                    <div className="mb-8">
                        <SyntaxHighlighter language="jsx" style={oneDark}  className="mb-8 codeBlock">
                        {`import 'lightbox.js-react/dist/index.css'\nimport {SlideshowLightbox} from 'lightbox.js-react'`}
                        </SyntaxHighlighter>
                    </div>

                    <h3 id="usage" className="text-gray-900 mb-4 text-3xl font-semibold" 
                    style={{ scrollMarginTop: '100px' }}>Creating the Metadata</h3>
                   <p>Next, let's initialize the metadata that contains the relevant information and links 
                    needed to render the picture tag: </p>
                   <div className="mb-8">
                        <SyntaxHighlighter language="js" style={oneDark} className="mb-8 codeBlock">
                        {`let media = [
  {"picture": {
    'image/webp': {
    srcSet: 'Image URL here',
    sizes: '100vw'
    },
    'image/jpeg': {
      srcSet: 'Image URL here',
      sizes: '100vw'
    },
    'fallback': 'Image URL here'
  }
}];
`}
</SyntaxHighlighter>
                
                    </div>

                    <p>Just remember to add your image URLs to the above code for the srcSet properties, as well as the <code>fallback</code>
                     {" "} property too. </p>

                    <p>Then just pass this array to the <code>images</code> prop in the <code>SlideshowLightbox</code> component.</p>
                    
                    <div className="mb-8">
                        <SyntaxHighlighter language="jsx" style={oneDark} className="mb-8 codeBlock">
                        {`<SlideshowLightbox images={images} lightboxIdentifier="lbox1" showThumbnails={true}>
   {images.map(item => {
       return <img src={item["picture"]['image/jpeg'].srcSet} data-lightboxjs="lbox1"  />
   })}
</SlideshowLightbox> `}
                        </SyntaxHighlighter>
                    </div>

                    <article className="mb-8 mt-8">
                      <h2 className="h2 text-gray-900 mb-4 text-3xl font-semibold">Customization</h2>
                    <p>For further customization, be sure to take a look at the <Link className="text-underline text-blue-600" 
                    to="/docs/SlideshowLightbox">
                        SlideshowLightbox</Link> {" "}
                     documentation, which lists the various customization options and props you can update to edit the 
                    theme and so forth.</p>
                   

      </article>
      
    </div>

    {/* Related content */}
    <div className="mt-8">
      <h3 className="h3 mb-8 font-semibold">Related</h3>
      <Link className="flex justify-between items-center p-4 rounded border border-gray-200 
      transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" to="/docs/SlideshowLightbox">
        <div>
          <div className="text-normal font-medium mb-1">Full SlideshowLightbox docs</div>
          <div className="text-sm text-gray-600">See how to customize the SlideshowLightbox in the documentation for this component here.</div>
        </div>
        <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </Link>
      <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition 
      duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="https://github.com/silvia-odwyer/lightbox.js">
        <div>
          <div className="text-normal font-medium mb-1">Feature request</div>
          <div className="text-sm text-gray-600">Want to request a new feature? Just open an issue in our GitHub repository!</div>
        </div>
        <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
        </svg>
      </a>
      <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
    </div>

    {/* <Feedback /> */}

  </div>

              </div>

              </div>
          </div>
          </section>

          </main>
      </div>
          
  </section>
    <Footer overrideDarkTheme={true} />
  </Layout>
  
}